import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { BankInformation } from './../../entity/BankInformation';
import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiBanqueService } from './../../services/ApiBanque/api-banque.service';
import { FilterBanque } from 'src/app/entity/filter-banque';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { autocompleteObjectValidator } from 'src/app/shared/Utils/sharedFunctions';
import swal from 'sweetalert2';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { environment } from 'src/environments/environment';
import { HistoryService } from 'src/app/pages/affaires/affaire-details/history.service';
@Component({
  selector: 'shared-information-bank',
  templateUrl: './shared-information-bank.component.html',
  styleUrls: ['./shared-information-bank.component.css'],
})
export class SharedInformationBankComponent implements OnInit, OnChanges {
  @Output() onChangeBankInformation: EventEmitter<BankInformation[]> = new EventEmitter();
  @Input() bankInformation: BankInformation[];
  @Input() idAffaire: string;
  @Input() typeEntity? = '';
  @Input() customStyle?: string = '';
  @Input() allowed_to_update_affaire: boolean;
  @Output() onChangesdetect: EventEmitter<boolean> = new EventEmitter();
  selectedBicSwift: string[] = [];
  detectedChange: boolean = false;
  bankForm: FormGroup;
  list_type_iban = [
    {
      key: 'Prélèvement',
      value: 'prélèvement',
    },
    {
      key: 'Remboursement',
      value: 'prestation',
    },
  ];

  // manuallyAddedBankIndices: Set<number> = new Set();
  temporaryTypeIbans: string[] = [];
  bicswift1: string;
  bank = new BankInformation();
  listBank: BankInformation[] = [];

  listBicPrestation: [] = [];
  listBicPrelevement: [] = [];
  showTypeAdd: boolean;
  isPasswordInputVisible: boolean = false;
  password: string = '';
  allow_to_change: boolean = false;
  showPassword: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private ApiBanqueService: ApiBanqueService,
    private apiAffairesService: ApiAffairesService,
    private HistoryService: HistoryService,

    private notificationsService: NotificationsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.onChangeBankInformation.emit(this.listBank);
  }

  fa = fa;

  showType = false;
  myControl: FormControl = new FormControl('', {
    validators: [autocompleteObjectValidator(), Validators.required],
  });

  filteredOptions: any[];
  subscribegetListBanque: Subscription;
  listBanque = [];
  filterListBanque: FilterBanque = new FilterBanque();
  ngOnInit(): void {
    this.getListBanque();
    if (this.bankInformation[0]?.id) {
      this.showType = false;
      this.listBank = this.bankInformation;
      this.listBank.forEach((element: BankInformation, index: number) => {
        this.temporaryTypeIbans.push(element.type_iban);
        if (element.titulaire_compte === 'None') {
          element.titulaire_compte = '';
        }

        // this.smellsLikeIban(index);
      });
    }

    this.onChangeBankInformation.emit(this.listBank);
  }

  // onInputBlur(index: number) {
  //   const inputValue = this.listBank[index].nom_banque;
  //   const isValidOption = this.filteredOptions.some((option) => option.nom === inputValue);

  //   if (!isValidOption) {
  //     this.listBank[index].nom_banque = '';
  //     this.listBank[index].bic_swift = '';
  //     if (this.temporaryTypeIbans[index] === 'prélèvement') {
  //       this.listBicPrelevement = [];
  //     } else {
  //       this.listBicPrestation = [];
  //     }
  //   }
  // }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  pushDetectChanges(){
    this.detectedChange = true;
      this.onChangesdetect.emit(this.detectedChange);
  }

  onNgModelChange(newValue: any, index: number) {

    const value = typeof newValue === 'string' ? newValue : newValue.nom;
    if (newValue) this.filteredOptions = this._affecterAfilter(value);
    if (newValue === '') {
      this.listBank[index].bic_swift = '';
      this.listBank[index].nom_banque = '';
      if (this.temporaryTypeIbans[index] === 'prélèvement') {
        this.listBicPrelevement = [];
      } else {
        this.listBicPrestation = [];
      }
      this.filteredOptions = [];
    }

    this.onChangeBankInformation.emit(this.listBank);
    this.pushDetectChanges();
  }

  saveBic(bic, index) {
    this.listBank[index].bic_swift = bic.code_swift;
  }

  displayFn(bank: any): string {
    return bank ? bank : ''; // eslint-disable-line
  }

  private _affecterAfilter(value: string) {
    const filterValue = value?.toLowerCase();
    if (filterValue) return this.listBanque.filter((option) => option.nom?.toLowerCase().indexOf(filterValue) > -1);
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent, index): void {
    this.listBank[index].nom_banque = event.option.value;

    const matchingBanque = this.listBanque.find((element) => element.nom === this.listBank[index].nom_banque);
    if (matchingBanque) {
      if (this.temporaryTypeIbans[index] === 'prélèvement') {
        this.listBicPrelevement = matchingBanque.banks;
      } else {
        this.listBicPrestation = matchingBanque.banks;
      }
    }
    this.filteredOptions = [];
  }

  getListBanque() {
    this.subscribegetListBanque = this.ApiBanqueService.getListBanqueV2(this.filterListBanque, '', '').subscribe(
      (response: any) => {
        this.listBanque = response.banques;

        this.listBank.forEach((listBankElement, bankIndex) => {
          const targetNom = listBankElement.nom_banque?.toLowerCase();

          const matchingBanque = this.listBanque.find((banque) => banque.nom?.toLowerCase() === targetNom);

          if (matchingBanque) {
            if (this.listBank[bankIndex].type_iban === 'prélèvement') {
              this.listBicPrelevement = matchingBanque.banks;
            } else {
              this.listBicPrestation = matchingBanque.banks;
            }
          }
        });
      }
    );
  }

  addBank(): void {
    const newIndex = this.listBank.length;
    this.listBank.push(new BankInformation());
    if (this.listBank.length === 2 && this.listBank[0].type_iban === 'prestation') {
      this.listBank[this.listBank.length - 1].type_iban = 'prélèvement';
    } else {
      this.listBank[this.listBank.length - 1].type_iban = 'prestation';
    }
    // this.manuallyAddedBankIndices.add(newIndex);

    this.changeBankInformation();
  }

  confirmUpdate(index) {
    swal
      .fire({
        title: 'Voulez-vous vraiment le  supprimer  ?',
        showDenyButton: true,
        confirmButtonText: `Oui`,
        denyButtonText: `Non`,
        icon: 'warning',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.removeBank(index);
        }
      });
  }

  removeBank(index): void {
    const deletedBank: any[] = [];

    if (!this.listBank[index].id) {
      this.listBank.splice(index, 1);
      this.temporaryTypeIbans.splice(index, 1);
      //   this.listBank.forEach((bank, i) => {

      //     this.manuallyAddedBankIndices.add(i);

      // });
    } else {
      deletedBank.push(this.listBank[index]);
      this.apiAffairesService.DeleteInfoBancaire(this.idAffaire, deletedBank).subscribe(
        (response) => {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.success;
          notificationMessage.title = 'Opération réussite';
          notificationMessage.message = response.message;
          this.notificationsService.sendMessage(notificationMessage);
          this.HistoryService.sendFetchDataSignal(true);
          this.listBank.splice(index, 1);
          this.temporaryTypeIbans.splice(index, 1);
          // this.manuallyAddedBankIndices.clear();
          // this.listBank.forEach((bank, i) => {

          //     this.manuallyAddedBankIndices.add(i);

          // });
          this.changeBankInformation();
        },
        (error) => {
          console.log(error);
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = 'Opération echoué';
          this.notificationsService.sendMessage(notificationMessage);
        }
      );
    }
  }

  isSameType(type: string): boolean {
    return type === this.listBank[1].type_iban && type === this.listBank[0].type_iban;
  }

  changeBankInformation(): void {
    this.temporaryTypeIbans = [];
    this.listBank.forEach((bank) => this.temporaryTypeIbans.push(bank.type_iban));

    const listIbanSend: BankInformation[] = [];
    if (this.listBank.length === 2) {
      if (this.listBank[0].type_iban === this.listBank[1].type_iban) {
        this.alertError('');
      } else {
        Object.assign(listIbanSend, this.listBank);
        this.pushDetectChanges()
        this.onChangeBankInformation.emit(listIbanSend);
      }
    } else {
      Object.assign(listIbanSend, this.listBank);
      this.pushDetectChanges()
      this.onChangeBankInformation.emit(listIbanSend);
    }
  }

  smellsLikeIban(index): void {
    if (this.listBank[index].iban) {
      this.detectedChange = true;
      this.onChangesdetect.emit(this.detectedChange);
      const nIban: string = this.listBank[index].iban
        .replace(/[^\dA-Za-z]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim();
      this.listBank[index].iban = nIban.toLocaleUpperCase().trim();
    }
  }

  togglePasswordInput() {
    this.isPasswordInputVisible = !this.isPasswordInputVisible;
    this.password = '';
  }

  validPass() {
    if (this.password) {
      if (this.password === environment.passwordUnlock) {
        this.allow_to_change = true;
        this.isPasswordInputVisible = false;
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.success;
        notificationMessage.title = 'Opération réussite';
        notificationMessage.message = 'Mot de passe validé';
        this.notificationsService.sendMessage(notificationMessage);
      } else {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = 'Opération echoué';
        notificationMessage.message = 'Mot de passe incorrecte';
        this.notificationsService.sendMessage(notificationMessage);
      }
    }
  }

  alertError(data) {
    swal.fire({
      title: 'Error',
      text: "Veuillez choisir un type d'Iban différent pour chaque compte bancaire !",
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }
}
