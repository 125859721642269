import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Gamme } from 'src/app/entity/Generic/Gamme';
import { environment } from 'src/environments/environment';
import { ApiOpportunitService } from '../../services/ApiOpportunite/api-opportunite.service';
class garantieProduit{
  code_garantie : any;
  cotisation : any;
  icon_garantie : any;
  Nb_de_sinistres_ans : any;
  Plafond_sinistre: any;
  Plafond_ans : any;
  unite : any;
  valeur : any;
  valeur_afficher : any=""; 
}
@Component({
  selector: 'app-group-garantie-produit-shared',
  templateUrl: './group-garantie-produit-shared.component.html',
  styleUrls: ['./group-garantie-produit-shared.component.css']
})

export class GroupGarantieProduitSharedComponent implements OnInit {
@Input () game : string;
@Input () listegarantie;
Gamme: Gamme = new Gamme();
ListG: garantieProduit[]=[];
currency :string
  @Output() listeGroupFrom = new EventEmitter<any>();

  constructor(private ApiOpportunitService: ApiOpportunitService,) { }

  ngOnInit(): void {
    this.ListG = []
    this.currency = environment.currentCurrency;
  
  }


  ngOnChanges() {

      this.ListG = this.listegarantie;
      this.listeGroupFrom.emit(this.ListG);

   // } 
   }
   valuechange(event , type , index){
      if (type == 'P_ans'){
        this.ListG[index].Plafond_ans = event.target.value;

      }else if(type == 'P_sinistre'){
        this.ListG[index].Plafond_sinistre = event.target.value;

      }else if(type == 'val'){
        this.ListG[index].valeur = event.target.value;

      }else if(type == 'nbr'){
      this.ListG[index].Nb_de_sinistres_ans = event.target.value;
    }else{
      this.ListG[index].valeur_afficher = event.target.value;
    }
     this.listeGroupFrom.emit(this.ListG);

   }
   numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}
