import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiOpportunitService } from '../../../services/ApiOpportunite/api-opportunite.service';
import { OpportunityList } from '../../../entity/Opportunity/OpportunityList';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { SharedMenuObserverService } from '../../../services/SharedMenuObserver/shared-menu-observer.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { FilterOpportunite } from '../../../entity/Opportunity/FilterOpportunite';
import swal from 'sweetalert2';
import { ApiVoIPService } from 'src/app/services/ApiVoIP/api-voip.service';
import { parseDate } from 'src/app/shared/Utils/sharedFunctions';
import { I } from '@angular/cdk/keycodes';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgbdmodalContent } from 'src/app/components/add-affaire-modal/add-affaire-modal.component';
import { ActionsGroupeeComponent } from 'src/app/components/actions-groupee/actions-groupee.component';
import { ApiAuthentificationService } from 'src/app/services/ApiAuthentification/api-authentification.service';
import { AccessList } from 'src/app/entity/accessList';
import { isEqual, differenceWith } from 'lodash';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { RangeDateComponent } from 'src/app/components/rangeDate/range-date/range-date.component';

class BodyOppUpdated {
  action_statut: boolean;
  statut: any;
  list_opportunites: any;
  action_add_tag: any;
}
@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.css'],
  providers: [DatePipe],
})
export class OpportunitiesComponent implements OnInit, OnDestroy ,AfterViewInit {
  Negociateur: boolean;
  isEdit: boolean = false;
  Statut: boolean;
  TagsOpportunites: boolean;
  TagsProspects: boolean;
  Communications: boolean;
  filtres_opportunite: FilterOpportunite = new FilterOpportunite();
  filtres_opportuniteInit: FilterOpportunite = new FilterOpportunite();
  listPhone = [];

  opportunityList: OpportunityList = new OpportunityList();
  limit = 15;
  pages = 1;
  intervale = 1;
  totalPages = 0;
  fa = fa;
  sort_field = 'default';
  sort_type: string = 'desc';
  list_tel = {
    mobile: '',
    tel: '',
    tel2: '',
  };

  @ViewChild('picker') picker: MatDateRangePicker<any>;
  readonly rangeDateCreation = RangeDateComponent;

  filterSsearch: boolean = false;

  dateForm: FormGroup;
  date_fin: any;
  date_debut: any;
  myDate = new Date();
  filtre: any = null;
  _unsubscribe: Subscription;
  checklist: any[] = [];
  checkedList: any;
  masterSelected: boolean = false;
  opp: any;
  bodyOppUpdated: BodyOppUpdated;
  actionButton: boolean = false;
  accessList: AccessList;
  act_grp_communication: boolean;
  act_grp_negociateur: boolean;
  act_grp_pubpostage: boolean;
  act_grp_statut: boolean;
  act_grp_tags: boolean;
  actionGroupeAccess: boolean;
  totalLength: number = 0;
  constructor(
    private router: Router,
    private apiOpportunitService: ApiOpportunitService,
    private apiVoIPService: ApiVoIPService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private apiAuthentificationService: ApiAuthentificationService,
    private route: ActivatedRoute ,
    private renderer: Renderer2

  ) {
    this.date_debut = new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate());
    this.date_fin = this.myDate;
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: Event) {
    localStorage.removeItem('filtre');
    localStorage.removeItem('pages');
    localStorage.removeItem('sort_field');
    localStorage.removeItem('sort_type');
    localStorage.removeItem('sSearch');
  }

  collapseFromParent : boolean ;
  @ViewChild('myDiv') myDiv: ElementRef;
  ngAfterViewInit() {
    this.renderer.listen(this.myDiv.nativeElement, 'click', () => {
      this.collapseFromParent = true;
      // Ajoutez votre logique ici
    });
  }

  getCollapseValue(event){
    this.collapseFromParent = event;
  }
  ngOnInit(): void {
    this.apiAuthentificationService.listOfAccess$.subscribe((value) => {
      this.accessList = value;
      this.act_grp_communication = this.accessList.act_grp_communication;
      this.act_grp_pubpostage = this.accessList.act_grp_pubpostage;
      this.act_grp_statut = this.accessList.act_grp_statut;
      this.act_grp_tags = this.accessList.act_grp_tags;

      if (this.act_grp_tags || this.act_grp_statut || this.act_grp_pubpostage) {
        this.actionGroupeAccess = true;
      } else {
        this.actionGroupeAccess = false;
      }
    });

    this.masterSelected = false;
    this.filtre = localStorage.getItem('filtre');
    const page = localStorage.getItem('pages');
    const sort_fields = localStorage.getItem('sort_field');
    const sort_types = localStorage.getItem('sort_type');
    if (sort_fields != null) {
      this.sort_field = sort_fields;
    }
    if (sort_types != null) {
      this.sort_type = sort_types;
    }

    if (page !== null) {
      this.pages = parseInt(page);
    }
    if (this.filtre !== null) {
      this.filtres_opportunite = JSON.parse(this.filtre);
      this.date_debut = this.filtres_opportunite.filtre_opp?.date_creation;
      this.date_fin = this.filtres_opportunite.filtre_opp.date_fin;
      if (this.filtre.sSearch) {
        this.getListOpportuniteSearch();
      } else {
        this.getListOpportunities();
      }
    } else {
      this.getListOpportunities();
    }
    this.sharedMenuObserverService.updateMenu('Opportunités');
    this.getCheckedItemList();
  }

  ngOnDestroy() {
    if (this.isEdit === false) {
      localStorage.removeItem('filtre');
      localStorage.removeItem('pages');
      localStorage.removeItem('sort_field');
      localStorage.removeItem('sort_type');
      localStorage.removeItem('savedFilter');
      localStorage.removeItem('savedCollection');
    } else {
      if (this.filtres_opportuniteInit.sSearch) {
        localStorage.setItem('filtre', JSON.stringify(this.filtres_opportuniteInit));
      } else {
        localStorage.setItem('filtre', JSON.stringify(this.filtres_opportunite));
      }
    }
    this._unsubscribe.unsubscribe();
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  getDataValue(event) {
    if (event.length > 3) {
      this.pages = 1;
      this.filterSsearch = true;
      this.filtres_opportuniteInit.filtre_opp.date_creation = null;
      this.filtres_opportuniteInit.filtre_opp.date_fin = null;
      this.filtres_opportuniteInit.sSearch = event;
      this.getListOpportuniteSearch();
    } else if (event.length === 0) {
      this.pages = 1;
      this.filterSsearch = true;
      this.filtres_opportuniteInit.filtre_opp.date_creation = this.datePipe.transform(
        new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate()),
        'yyyy-MM-dd'
      );
      this.filtres_opportuniteInit.filtre_opp.date_fin = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
      this.filtres_opportuniteInit.sSearch = event;
      this.getListOpportuniteSearch();
    }
  }

  getListOpportuniteSearch() {
    this.router.navigate([], {
      queryParams: {
        filter: null,
        collection: null,
      },
      queryParamsHandling: 'merge',
    });
    this.showloader();
    this._unsubscribe = this.apiOpportunitService
      .listOpportunitesGet(this.filtres_opportuniteInit, this.pages, this.limit, this.sort_type, this.sort_field)
      .subscribe((data: OpportunityList) => {
        this.opportunityList = data;
        this.date_debut = parseDate(data.min_date_depot);
        this.date_fin = parseDate(data.max_date_depot);
        this.opportunityList.aaData.forEach((element, index) => {
          const dateticket = parseDate(element.date_depot);
          element.date_depot = dateticket.toString();
          element.isSelected = false;
        });

        this.calculate_nb_pages(data.iTotalDisplayRecords);
        this.hideloader();
      });
  }

  getDataValuee($event) {
    const date_debutinit = new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate());
    const date_fininit = this.myDate;
    this.filterSsearch = false;
    localStorage.removeItem('pages');
    this.pages = 1;
    if ($event === 'submit') {
      if (this.isObjectEqual()) {
        this.date_debut = new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate());
        this.date_fin = this.myDate;
      } else if (
        this.filtres_opportunite.filtre_opp.date_creation &&
        this.filtres_opportunite.filtre_opp.date_fin &&
        (this.filtres_opportunite.filtre_opp.date_creation !== this.datePipe.transform(date_debutinit, 'yyyy-MM-dd') ||
          this.filtres_opportunite.filtre_opp.date_fin !== this.datePipe.transform(date_fininit, 'yyyy-MM-dd'))
      ) {
        this.date_debut = this.filtres_opportunite.filtre_opp.date_creation;
        this.date_fin = this.filtres_opportunite.filtre_opp.date_fin;
      } else {
        this.date_debut = null;
        this.date_fin = null;
      }
    } else if ($event === 'opportunite') {
      if (this.isObjectEqual()) {
        this.date_debut = new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate());
        this.date_fin = this.myDate;
      } else {
        this.date_debut = this.filtres_opportunite.filtre_opp.date_creation;
        this.date_fin = this.filtres_opportunite.filtre_opp.date_fin;
      }
    } else if ($event === 'reset') {
      this.filtres_opportunite = new FilterOpportunite();
      this.date_debut = new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate());
      this.date_fin = this.myDate;
    }

    this.getListOpportunities();
  }

  isObjectEqual() {
    this.filtres_opportuniteInit.filtre_opp.date_creation = null;
    this.filtres_opportuniteInit.filtre_opp.date_fin = null;
    const differences = differenceWith([this.filtres_opportunite, this.filtres_opportuniteInit]);
    return isEqual(this.filtres_opportunite, this.filtres_opportuniteInit);
  }

  getListOpportunities() {
    this.showloader();
    // JSON.parse(localStorage.getItem('filtre'));
    this.filtres_opportunite.filtre_opp.date_creation = this.datePipe.transform(this.date_debut, 'yyyy-MM-dd');
    this.filtres_opportunite.filtre_opp.date_fin = this.datePipe.transform(this.date_fin, 'yyyy-MM-dd');

    this._unsubscribe = this.apiOpportunitService
      .listOpportunitesGet(this.filtres_opportunite, this.pages, this.limit, this.sort_type, this.sort_field)
      .subscribe((data) => {
        this.opportunityList = data;
        this.date_debut = parseDate(data.min_date_depot);
        this.date_fin = parseDate(data.max_date_depot);
        this.opportunityList.aaData.forEach((element, index) => {
          const dateticket = parseDate(element.date_depot);
          element.date_depot = dateticket.toString();
          element.isSelected = false;
          /*   let check = new Checklist();
         check.id =  element.opportunite_id
          check.isSelected = false;
          this.checklist[index] = check; */
        });
        this.filtres_opportuniteInit.sSearch = this.filtres_opportunite.sSearch;
        this.totalLength = data.iTotalDisplayRecords;
        this.calculate_nb_pages(data.iTotalDisplayRecords);
        this.hideloader();
      });
  }

  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.pages = this.intervale;
      if (this.pages <= this.totalPages) {
        if (this.filterSsearch === true) {
          this.getListOpportuniteSearch();
        } else {
          this.getListOpportunities();
        }
      }
    }
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.pages - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.pages + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.pages = val;
    if (this.filterSsearch === true) {
      this.getListOpportuniteSearch();
    } else {
      this.getListOpportunities();
    }
  }

  isFirst(): boolean {
    return this.pages === 1;
  }

  isLast(): boolean {
    return this.pages === this.totalPages;
  }

  updateListOpportunities() {
    this.pages = 1;
    if (this.filterSsearch === true) {
      this.getListOpportuniteSearch();
    } else {
      this.getListOpportunities();
    }
  }

  changepage(number: number) {
    this.pages = number;
    localStorage.setItem('pages', number.toString());
    if (this.filterSsearch === true) {
      this.getListOpportuniteSearch();
    } else {
      this.getListOpportunities();
    }
  }

  alertSuccess(data) {
    let timerInterval;
    swal.fire({
      position: 'center',
      title: 'Opération Réussie',
      text: data,
      showConfirmButton: false,
      timer: 2500,
      timerProgressBar: true,
      didOpen: () => {
        swal.showLoading();
        timerInterval = setInterval(() => {}, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
      allowOutsideClick: false,
    });
  }

  add_suivi_opp(opportunite_id: string) {
    this.apiOpportunitService.addSuiviOppGet(opportunite_id).subscribe((response) => {
      this.alertSuccess(response.message);
    });
  }

  setSortField(sort_field: string) {
    if (this.sort_field === sort_field) {
      if (this.sort_type === 'desc') {
        this.sort_type = 'asc';
      } else {
        this.sort_type = 'desc';
      }
    } else {
      this.sort_type = 'asc';
    }
    this.sort_field = sort_field;
    localStorage.setItem('sort_field', this.sort_field);
    localStorage.setItem('sort_type', this.sort_type);
    if (this.filterSsearch === true) {
      this.getListOpportuniteSearch();
    } else {
      this.getListOpportunities();
    }
  }

  getPhoneList(id_opp: string) {
    this.apiOpportunitService.getProspectTelByOppId(id_opp).subscribe((data) => {
      this.list_tel.mobile = data.list_tel.mobile;
      this.list_tel.tel = data.list_tel.tel;
      this.list_tel.tel2 = data.list_tel.tel2;
    });
  }

  callVoip(number) {
    this.showloader();
    this.apiVoIPService.callVoipGet(number).subscribe((data) => {
      this.hideloader();
      if (data.etat_ws === 'ERROR Merci de vérifier vos coordonnées d’accès ') {
        this.alertErrorVoip();
      }
    });
  }

  openDatePicker() {
    if (this.picker) {
      this.picker.open();
    }
  }

  clearDateCreation() {
    this.date_debut = null;
    this.date_fin = null;
  }

  alertErrorVoip() {
    swal
      .fire({
        title: 'Vérifiez votre configuration VoIP!',
        text: 'Vérifiez votre numéro interne VoIP',
        icon: 'error',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: true,
        cancelButtonText: 'Fermer',
        confirmButtonText: 'Configurer',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/profile']).then(() => {
            window.location.reload();
          });
        }
      });
  }

  EmitFilterOpportunite($event: FilterOpportunite) {
    this.filtres_opportunite = $event;
  }

  toAdd() {
    this.router.navigate(['opportunities/add']);
  }

  updateOpportunities() {
    const options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'lg',
      scrollable: true,
      windowClass: 'my-class ',
      centered: true,
      animation: true,
      backdrop: 'static',
      keyboard: false,
    };
    const modeldoc = this.modalService.open(ActionsGroupeeComponent, options);
    modeldoc.componentInstance.disabledchamp = false;
    modeldoc.componentInstance.type = 'opp';
    modeldoc.componentInstance.listeOpp = this.checkedList;

    modeldoc.result.then(
      (res) => {},
      (dismiss) => {
        if (dismiss !== '' && dismiss !== undefined) {
          if (dismiss !== 'error') {
            this.alertSuccesss(dismiss);
          } else {
            this.alertError('Permission refusée');
          }
          //  this.getListOpportunities();
        }

        /*   if(dismiss !== '' && dismiss !== undefined){
      let bodyOppUpdated = new BodyOppUpdated() ;
      bodyOppUpdated.action_statut = true;
      bodyOppUpdated.statut = dismiss
      bodyOppUpdated.list_opportunites =  this.checkedList

    this.apiOpportunitService.multipleActionOpportunite(bodyOppUpdated).subscribe((data)=>{
    //let message =data.nb_modified + ' Opportunités modifiées \n & ' +data.nb_not_modified+  ' Opportunités non modifiées '
    this.alertSuccesss(data.nb_modified_status,data.nb_not_modified_status);
     this.getListOpportunities();

 })
} */
      }
    );
    /*    modeldoc.componentInstance.event.subscribe((data: any) => {
      console.log('data from  opp',data)
      modeldoc.close();
      this.updateOpportunities();
    }); */
  }

  checkUncheckAll() {
    for (let i = 0; i < this.opportunityList.aaData.length; i++) {
      this.opportunityList.aaData[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  isAllSelected() {
    this.masterSelected = this.opportunityList.aaData.every(function (item: any) {
      return item.isSelected === true;
    });
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (let i = 0; i < this.opportunityList.aaData?.length; i++) {
      if (this.opportunityList.aaData[i].isSelected)
        this.checkedList.push(this.opportunityList.aaData[i].opportunite_id);
    }
    if (this.checkedList.length > 0) {
      this.actionButton = true;
    } else {
      this.actionButton = false;
    }
    // this.checkedList = JSON.stringify(this.checkedList);
  }

  alertSuccesss(data) {
    const total =
      data.nb_modified_status +
      data.nb_modified_add_tags +
      data.nb_modified_delete_tags +
      data.nb_modified_tag_prospect;

    swal
      .fire({
        icon: 'info',
        html:
          " <div class='row justify-content-center'>  <div class='card-info-1'><p class='p-info-2' style=' padding-top: 13px;font-size: 14px; text-align: center; '>" +
          data.nb_total +
          ' Eléments séléctionnnés. <br> ' +
          total +
          " Modifications effectuées: </p></div></div> <div class='row justify-content-center '><div class='col-4'>  <div class='card-info-3' style='  width: 100%; height: 90px;border-radius: 10px; background-color: #E2F4EC;color: #4A9D77;cursor: pointer;' ><p class='p-info-2'  style=' padding-top: 13px;font-size: 14px;text-align: center; '> <img src='assets/icons/image_2023_01_13T09_16_51_979Z.png' width='20' height='20'> <br>" +
          data.nb_modified_status +
          " Status modifiées</p><p class='p-info-1' style='font-size: 15px;text-align: center;  font-weight: bold;'>" +
          "</p></div></div><div class='col-4'><div class='card-info-3'style='  width: 100%;height: 90px;border-radius: 15px; background-color: #E2F4EC;color: #4A9D77;cursor: pointer;' >  <p class='p-info-2'  style=' padding-top: 13px;font-size: 14px;text-align: center; '><img src='assets/icons/image_2023_01_12T14_48_35_211Z.png' width='20' height='20'> <br>" +
          data.nb_modified_add_tags +
          " Mots clés ajoutés</p><p class='p-info-1' style='font-size: 15px;text-align: center; font-weight: bold;'> " +
          "</p></div></div><div class='col-4'><div class='card-info-3' style='  width: 100%;height: 90px;border-radius: 15px; background-color: #E2F4EC;color: #4A9D77;cursor: pointer;' ><p class='p-info-2'  style=' padding-top: 13px;font-size: 14px;text-align: center; '> <img src='assets/icons/image_2023_01_12T14_47_34_190Z.png' width='20' height='20'><br>" +
          data.nb_modified_delete_tags +
          " Mots clés supprimés</p><p class='p-info-1' style='font-size: 15px;text-align: center ; font-weight: bold;'>" +
          "</p></div> </div></div> <div class='row row justify-content-center' style='margin-top:10px;'> <div class='col-4' > <div class='card-info-3'style='  width: 100%;height: 90px;border-radius: 15px; background-color: #E2F4EC;color: #4A9D77;cursor: pointer;' >  <p class='p-info-2'  style='font-size: 14px;text-align: center; padding-top:13px; '><img src='assets/icons/image_2023_01_12T14_54_07_809Z.png' width='20' height='20'> <br> " +
          data.nb_modified_tag_prospect +
          " Mots clés prospect ajoutés</p><p class='p-info-1' style='font-size: 15px;text-align: center; font-weight: bold;'> " +
          '</p></div>  </div> </div>',
        cancelButtonColor: '#138eb6',
        focusConfirm: true,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Fermer',
      })
      .then((result) => {
        this.hideloader();

        this.masterSelected = false;
        if (this.filterSsearch === true) {
          this.getListOpportuniteSearch();
        } else {
          this.getListOpportunities();
        }
        for (let i = 0; i < this.opportunityList.aaData?.length; i++) {
          this.opportunityList.aaData[i].isSelected = false;
          this.actionButton = false;
        }
      });
  }

  alertError(data) {
    swal.fire({
      title: "Quelque chose s'est mal passé!",
      text: data,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  goToEdit() {
    this.route.queryParamMap.subscribe((queryParams) => {
      if (queryParams.get('filter') !== null && queryParams.get('collection') !== null) {
        localStorage.setItem('savedFilter', queryParams.get('filter'));
        localStorage.setItem('savedCollection', queryParams.get('collection'));
      }
    });
    this.isEdit = true;
  }
}
