<div id="tickets_model" [ngClass]="{'cover': !allowed_to_update_affaire}">
    <div class="card bg-white border-0 p-3">
        <div class="card-doc-title ml-3 row">
            <fa-icon class="mr-2 fa-lg " [icon]="fa['faTicketAlt']"></fa-icon>
            Tickets
        </div>
        <button type="button" class="btn-document-add btn-position-add" data-title="Ajouter un ticket" data-toggle="modal" data-target="#AjoutTicketModal">
          <fa-icon class="fa fa-lg" [icon]="fa['faPlus']"></fa-icon>
        </button>
        <ng-container *ngIf="!show_loading_add">

            <div class="row mb-3 mt-3">
                <div class="text-center m-auto ">
                    <mat-spinner [diameter]="30"></mat-spinner>

                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="show_loading_add">
            <div class="p-3 scrollbar" [ngClass]="{'table-scroll': ticket_details.length > 10 }">
                <table class="table align-items-center" id="dataTable1">
                    <thead class="head-table">
                        <tr>
                            <th>Date de Création</th>
                            <th>Créateur</th>

                            <th>Ticket</th>
                            <th>Catégorie</th>
                            <th>Statut</th>
                        </tr>
                    </thead>
                    <tbody class="body-table">
                        <tr *ngFor="let ticket of ticket_details">
                            <td> {{ticket.date_creation}}</td>
                            <td>{{ticket.createur_name}}</td>

                            <td>
                                <button *ngIf="ticket.id != '' && ticket.id != undefined" type="button" class="btn-dt-link" (click)="NaviagateToTicketDetials(ticket.id)">
                                <fa-icon class="fa-icon-style" [icon]="fa['faLink']"></fa-icon>&nbsp;
                                Détails Ticket
                              </button>
                            </td>
                            <td>{{ticket.categorie}}</td>
                            <td>
                                <div class="label-status " [ngStyle]="{'background':ticket ?.statut_background}">
                                    <span class="pt-2" [ngStyle]="{'color':ticket?.statut_color}">
                                <fa-icon class="mr-1" [icon]="fa[ticket?.icon]"></fa-icon>
                                {{ticket.statut}}
                              </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="ticket_details.length < 1" class="text-center font-weight-bold pt-1">
                    <div class="col-12  d-flex justify-content-center">
                        <img src="assets/icons/nodata.svg">

                      </div>
                      <div class="col-12  d-flex justify-content-center">
                        <p class="noData">Pas de données disponibles</p>
                      </div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="modal fade" id="AjoutTicketModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content" style="width: 60rem; ">
                <div class="modal-body p-0">
                    <app-add-tickets-affaire [prospect_id]="prospect_id"></app-add-tickets-affaire>
                </div>
            </div>
        </div>
    </div>
</div>
