import { map, tap } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { Component, Input, OnDestroy, OnInit ,ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { CycleVie } from 'src/app/entity/CycleVie';
import { Observable, Subscription } from 'rxjs';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
import swal from 'sweetalert2';
import { CycleVieBpm } from 'src/app/entity/Bpm/CycleVieBpm';
import { autocompleteObjectValidator } from 'src/app/shared/Utils/sharedFunctions';
import { element } from 'protractor';


import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-fiche-workflow',
  templateUrl: './fiche-workflow.component.html',
  styleUrls: ['./fiche-workflow.component.css']
})
export class FicheWorkflowComponent implements OnInit, OnDestroy {

  fa = fa;
  show_loading_add: boolean = false;
  cycle: CycleVie = new CycleVie();
  cycleBpm: CycleVieBpm = new CycleVieBpm();
  initialListeCycle: CycleVieBpm = new CycleVieBpm();
  libelle: string = '';
  listeCycle: any;
  list_invalid: any[] = [];
  etat_opp: any[] = [];
  list_invalid_string: string = '';
  initial_libele: string = '';
  listeOfUnsubscriptionWebService: Subscription[] = [];
  libelleFormControl: FormControl = new FormControl('',{
    validators: [ Validators.required],
  });
  affecterCycleFormControl: FormControl = new FormControl('', {
    validators: [autocompleteObjectValidator()],
  });

  selectedServices = [];
  bpmServices =[]
  serviceApresVente :any 
  listServices: any
  listCycleVieFilter: Observable<any[]> = null;
  listCycleVie: any[] = [];
  affecteCycle: string = '';
  cycleVie_affecte: any;
  @Input() id_etat_opp;
  @Input() ModeAjout;
  @Input() type;


  constructor(
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiAdminBpmServices: ApiAdminBpmService,
    private routes: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnDestroy(): void {
    this.listeOfUnsubscriptionWebService.forEach((element) => {
      element.unsubscribe();
    })
  }

  ngOnInit(): void {
this.showloader()
    if(this.id_etat_opp!==''){
      if(this.type === 'c'){
        this.listeAffectCycleVie(this.id_etat_opp);
        const unsubscribeGetCdvById = this.apiAdminBpmServices
        .getCdvById(this.id_etat_opp)
        .pipe(
          tap(({ cdv }) => {
            this.cycleBpm.libele = cdv.libele;
            this.initial_libele = cdv.libele;
            this.cycleVie_affecte=cdv.statut_depart
            if (this.cycleVie_affecte){

              const affected_to_cycleVie: any = this.listCycleVie.filter((elem) => elem.id === this.cycleVie_affecte).pop();

              if (affected_to_cycleVie !== undefined) this.affecterCycleFormControl.setValue(affected_to_cycleVie);
            }
            this.cycleBpm = cdv;
            this.selectedServices =cdv.services
            this.selectedServices = this.selectedServices.sort((a, b) => a.order - b.order);
            this.updateBpmServices();
            this.selectedServices?.forEach(selectedService => {
              const listService = this.listServices?.find(item => item.id === selectedService.id);
              if (listService) {
                listService.checked = true;
                listService.selected = true;
              }
            });
            Object.assign(this.initialListeCycle, this.cycleBpm);
            this.hideloader();
          })
        )
        .subscribe(); this.listeOfUnsubscriptionWebService.push(unsubscribeGetCdvById);

    
      }else{
        this.listeAffectCycleVieProd(this.id_etat_opp);
        const unsubscribeGetCdvById = this.apiAdminBpmServices
        .getCdvPById(this.id_etat_opp)
       .pipe(
          tap(({ cycle_de_vie_prod }) => {
            this.cycleBpm.libele = cycle_de_vie_prod.libele;
            this.selectedServices =cycle_de_vie_prod.services
            this.selectedServices = this.selectedServices.sort((a, b) => a.order - b.order);
            this.cycleVie_affecte=cycle_de_vie_prod.statut_depart_prod
            if (this.cycleVie_affecte){

              const affected_to_cycleVie: any = this.listCycleVie.filter((elem) => elem.id === this.cycleVie_affecte).pop();

              if (affected_to_cycleVie !== undefined) this.affecterCycleFormControl.setValue(affected_to_cycleVie);
            }
        

            this.updateBpmServices();
            this.selectedServices.forEach(selectedService => {
              const listService = this.listServices.find(item => item.id === selectedService.id);
              if (listService) {
                listService.checked = true;
                listService.selected = true;
              }
            });
    
            this.initial_libele = cycle_de_vie_prod.libele;
            this.cycleBpm = cycle_de_vie_prod;
            Object.assign(this.initialListeCycle, this.cycleBpm);
            this.hideloader();
          })
        ) 
        .subscribe(); 
        this.listeOfUnsubscriptionWebService.push(unsubscribeGetCdvById);



   
      }
      
      }
        this.getCycleVieComm();
      

    if (this.ModeAjout) {
      if(this.type ==='c'){
        this.sharedMenuObserverService.updateMenu('Workflow commercial ➜ Ajouter');
        const unsubscribeGetListCategorieStatue = this.apiAdminBpmServices.getListCategorieStatue().subscribe((data) => {
          this.listServices = data.cat_status_opp_list;
          this.hideloader()
        })
          this.listeOfUnsubscriptionWebService.push(unsubscribeGetListCategorieStatue);

      }else{
        this.sharedMenuObserverService.updateMenu('Workflow production ➜ Ajouter');

      const unsubscribeGetListServiceAffaire = this.apiAdminBpmServices.getListServiceAffaire().subscribe((data) => {
        this.listServices = data.cat_status_aff_list;
        this.hideloader()})
        this.listeOfUnsubscriptionWebService.push(unsubscribeGetListServiceAffaire);
      }
    } else {
      if(this.type ==='c'){
        this.sharedMenuObserverService.updateMenu('Workflow commercial ➜ Modifier');
        const unsubscribeGetListCategorieStatue = this.apiAdminBpmServices.getListCategorieStatue().subscribe((data) => {
          this.listServices = data.cat_status_opp_list;
          this.selectedServices.forEach(selectedService => {
            const listService = this.listServices.find(item => item.id === selectedService.id);
            if (listService) {
              listService.checked = true;
              listService.selected = true;
            }
          });
          this.hideloader()
        })
          this.listeOfUnsubscriptionWebService.push(unsubscribeGetListCategorieStatue);
      }else{
        this.sharedMenuObserverService.updateMenu('Workflow production ➜ Modifier');
        const unsubscribeGetListServiceAffaire = this.apiAdminBpmServices.getListServiceAffaire().subscribe((data) => {
          this.listServices = data.cat_status_aff_list;
          this.selectedServices.forEach(selectedService => {
            const listService = this.listServices.find(item => item.id === selectedService.id);
            if (listService) {
              listService.checked = true;
              listService.selected = true;
            }
          });
          this.hideloader()
        })
          this.listeOfUnsubscriptionWebService.push(unsubscribeGetListServiceAffaire);

      }
    }
    this.cdr.detectChanges();
  }


  deleteService(service) {
    const index = this.selectedServices.indexOf(service);
    if (index !== -1) {
      this.selectedServices.splice(index, 1);
      const listService = this.listServices.find(item => item.id === service.id);
    if (listService) {
      listService.checked = false;
      listService.selected = false;
    }
      this.updateBpmServices(); // Update bpmservices after removing the item
    }
  }

  getCycleVieComm() {
    this.listCycleVieFilter = this.affecterCycleFormControl.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value.libele)),
      map((value) => {

        return this._affecterfilterCycleVie(value);
        
      })
    );

  }

  onDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.selectedServices, event.previousIndex, event.currentIndex);
    this.updateBpmServices();
  }

  updateSelectedServices(service) {
    if (service.selected) {
      // make unique items 
      if (!this.selectedServices.includes(service.id)){
        this.selectedServices.push(service);
      }


    } else {
      const index = this.selectedServices.findIndex(selected => selected.id === service.id);
      if (index !== -1) {
        this.selectedServices.splice(index, 1);
      }
    }
    this.cdr.detectChanges();
    this.updateBpmServices();
  }
  
  updateBpmServices() {
    this.bpmServices = this.selectedServices.map(service => service.id);
  }
 
  listeAffectCycleVie(id_opp) {
    const unsubscribeGetListStatus = this.apiAdminBpmServices.getListStatus(id_opp).subscribe((data) => {
      this.listCycleVie = data.etat_opp_list;
      const affected_to_cycleVie: any = this.listCycleVie.filter((elem) => elem.id === this.cycleVie_affecte).pop();

      if (affected_to_cycleVie !== undefined) this.affecterCycleFormControl.setValue(affected_to_cycleVie);
    }); this.listeOfUnsubscriptionWebService.push(unsubscribeGetListStatus);
  }

  listeAffectCycleVieProd(id_opp) {
    const unsubscribeGetListStatus = this.apiAdminBpmServices.getListStatusProd(id_opp).subscribe((data) => {
      this.listCycleVie = data.etat_dossier;
      const affected_to_cycleVie: any = this.listCycleVie.filter((elem) => elem.id === this.cycleVie_affecte).pop();
      if (affected_to_cycleVie !== undefined) this.affecterCycleFormControl.setValue(affected_to_cycleVie);

      
    }); this.listeOfUnsubscriptionWebService.push(unsubscribeGetListStatus);
  }


  private _affecterfilterCycleVie(value: string): any[] {
    const filterValue = value?.toLowerCase();
    return this.listCycleVie.filter((option) => option.etat?.toLowerCase().indexOf(filterValue) > -1);
  }

  public displayCycleVieFn(cyclevie?: any): string | undefined {
    this.cycleVie_affecte = cyclevie?.id;
    if (typeof cyclevie === 'string') {
      return cyclevie || null;
    } else {
      return cyclevie ? cyclevie.etat : null;
    }
  }

  Enregistrer() {
   this.libelleFormControl.markAsTouched();
    this.show_loading_add = true;
    if (this.bpmServices.length < 1 && this.libelleFormControl.valid){
      this.alertError('Les services sont  obligatoires', 'Erreur');
      this.show_loading_add = false;
    }else if (this.libelleFormControl.invalid){
      this.alertError('Le Libelle est obligatoire' , 'Erreur');
        this.show_loading_add=false
    }else {
    if(this.type ==='c'){
           // workflow commercial
    if (this.ModeAjout === true) {
        this.cycleBpm.services = this.bpmServices
        const unsubscribeCreateCycleVie = this.apiAdminBpmServices.createCycleVie(this.cycleBpm).subscribe(
          (data) => {
            this.show_loading_add = false;
            this.alertSuccess("Cycle de vie commercial ajouté", data.id, true);
          },
          (err) => {
            this.alertWarning(err);
            this.show_loading_add = false;
          }
        ); this.listeOfUnsubscriptionWebService.push(unsubscribeCreateCycleVie);
 
    } else {
      // if (this.affecterCycleFormControl.value.id){
      //   this.cycleBpm.statut_depart= this.affecterCycleFormControl.value.id
      // }else {
        this.cycleBpm.statut_depart=null
     // }
    
      this.cycleBpm.id = this.id_etat_opp;
      this.cycleBpm.services = this.bpmServices
      const unsubscribeupdateCycleVie = this.apiAdminBpmServices.updateCycleVie(this.cycleBpm).subscribe(
        (data) => {
          this.show_loading_add = false;
          this.alertSuccess("Cycle de vie commercial mis à jour", data.id, false);
        },
        (err) => {
          if (err) {
            this.show_loading_add = false;
            this.alertWarning("Erreur");
          }
        }
      ); this.listeOfUnsubscriptionWebService.push(unsubscribeupdateCycleVie);
    }
  }else{
 // workflow production
 if (this.ModeAjout === true) {
    this.cycleBpm.services = this.bpmServices
  //  if (this.affecterCycleFormControl.value.id){
   // this.cycleBpm.statut_depart= this.affecterCycleFormControl.value.id}
   // else {
      this.cycleBpm.statut_depart=null
   // }
    const unsubscribeCreateCycleVie = this.apiAdminBpmServices.createCycleVieProduction(this.cycleBpm).subscribe(
      (data) => {
        this.show_loading_add = false;
        this.alertSuccess("Cycle de vie de production ajouté", data.id, true);
      },
      (err) => {
        console.log(err)
        this.alertWarning("Erreur");
        this.show_loading_add = false;
      }
    ); this.listeOfUnsubscriptionWebService.push(unsubscribeCreateCycleVie);
  
 }else{
  if (this.affecterCycleFormControl.value.id){
  this.cycleBpm.statut_depart= this.affecterCycleFormControl.value.id}
  else {
    this.cycleBpm.statut_depart=null
  }
  this.cycleBpm.id = this.id_etat_opp;
  this.cycleBpm.services = this.bpmServices
  const unsubscribeupdateCycleVie = this.apiAdminBpmServices.updateCycleVieProduction(this.cycleBpm).subscribe(
    (data) => {
      this.show_loading_add = false;
      this.alertSuccess("Cycle de vie de production mis à jour", data.id, false);
    },
    (err) => {
  console.log(err)
        this.show_loading_add = false;
        this.alertWarning("Erreur");

    }
  ); this.listeOfUnsubscriptionWebService.push(unsubscribeupdateCycleVie);
 }
  }
  }}

  onStatusChange(newValue: string) {
    if (!newValue && !this.ModeAjout){
      this.affecterCycleFormControl.value.id = null
    }
    }
   

  alertSuccess(data, id, modeedit) {
    swal
      .fire({
        title: 'Opération Réussie!',
        text: data,
        icon: 'success',
        showDenyButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: modeedit,
        denyButtonText: 'Nouveau cycle de vie',
        denyButtonColor: '833626',
        cancelButtonText: 'Modifier cycle de vie',
        confirmButtonText: 'Liste des cycles des vies',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.hideloader();
          if(this.type === 'c'){
            this.routes.navigate(['gestion-cycle-vies']);
          }else{
            this.routes.navigate(['gestion-cycle-vies-production']);
          }
        }
        if (result.isDismissed) {
          this.hideloader();
            if(this.type === 'c'){
              this.routes.navigate(['/gestion-cycle-vies/fiche/' + id]).then((result) => {
                this.hideloader();
                location.reload();
              });;
             // this.ngOnInit();
            } 
          else{
            this.routes.navigate(['/gestion-cycle-vies-production/fiche/' + id]).then((result) => {
              this.hideloader();
              location.reload();
            });
           // this.ngOnInit();
            }
        }
        if (result.isDenied) {
          if(this.type === 'c'){
            this.routes.navigate(['/gestion-cycle-vies/fiche/nouveau']).then((result) => {
              this.hideloader();
              location.reload();
            });
          }else{
            this.routes.navigate(['/gestion-cycle-vies-production/fiche/nouveau']).then((result) => {
              this.hideloader();
              location.reload();
            });
          }
       
        }
      });
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  alertWarning(data) {
    this.list_invalid_string = '';
    this.list_invalid.forEach((element) => {
      if (typeof element.name === 'string')
        this.list_invalid_string =
          this.list_invalid_string + '<li style="list-style-type: none; font-size: 14px">' + element.name + '</li>';
    });
    if (this.list_invalid_string.length > 0) {
      swal.fire({
        title: data,
        icon: 'warning',
        html: '<b>Il faut remplir tout les champs!</b><br>' + '<ul>' + this.list_invalid_string + '</ul>',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: 'Fermer',
        confirmButtonColor: '#e75e5e',
      });
    }
  }

  alertError(data, title) {
    swal.fire({
      title: title,
      text: data,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  retourToList() {
    if(this.type ==='c'){
      this.routes.navigate(['/gestion-cycle-vies']);
    }else{
      this.routes.navigate(['/gestion-cycle-vies-production']);
    }

  }

  clearForm() {

    if (!this.ModeAjout) {
      Object.assign(this.cycleBpm, this.initialListeCycle);
     // this.selectedServices =this.cycleBpm.services
      this.ngOnInit()
    } else {
      this.cycleBpm = new CycleVieBpm();
      this.libelleFormControl.reset();
      this.affecterCycleFormControl.reset();
      this.cycleBpm.libele = '';
   
     
      this.selectedServices.forEach(element => {
        const listService = this.listServices.find(item => item.id === element.id);
        if (listService) {
          listService.checked = false;
          listService.selected = false;
        }
      });
      this.cycleBpm.services =[];
      this.bpmServices =[];
      this.selectedServices =[];
     
     
    }
  }



}
