<div class="card-head" *ngIf="!ModeAjout">
    <div class="row">
        <div class="col-9 mt-2">
            <p>
                Cycle de vie n° :
                <label class="label-card-info">
            {{cycleBpm?.real_id}}
          </label>
            </p>
            <p>
                Nom du Cycle de vie :
                <label class="label-card-info">
          {{cycleBpm.libele}}
        </label>
            </p>
        </div>
        <div *ngIf="this.affecterCycleFormControl.value.id" class="col-3 text-left">
            <p> Statut :
                <label class="label-card-info">
          {{this.affecterCycleFormControl.value.etat}}
        </label>
            </p>
        </div>
    </div>
</div>

<div class="card-head text-center">
    <div class="row details-header">
        <button type="button" (click)="retourToList()" class="btn-dt-retourr mt-1" >
            <div class="icon-position">
                <fa-icon class="fa-icon-style" [icon]="fa['faArrowLeft']"></fa-icon>&nbsp;
            </div>
        </button>
        <div class="row">
            <div class="row details-spinner">
                <div class="col">
                    <ng-container *ngIf="show_loading_add">
                        <div class="mt-2">
                            <mat-spinner [diameter]="30"></mat-spinner>
                        </div>
                    </ng-container>
                </div>
                <div class="col mr-3">
                    <button type="button" class="btn-load" id="clear" (click)="clearForm()">
          <fa-icon [icon]="fa['faRedo']"></fa-icon>
    </button>
                </div>
            </div>
            <button type="button" class="btn-dt-save" (click)="Enregistrer()">
    <fa-icon class="fa-lg" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
      Enregistrer
  </button>
        </div>
    </div>
</div>

<div class="card p-3 border-0 m-3">
    <div class="row mt-3">
        <div class="col-6">
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label>Libelle <span style="color: red;"> *</span></mat-label>
                    <input name="nom_organisme" [(ngModel)]="cycleBpm.libele"   [formControl]="libelleFormControl" matInput  placeholder="Libelle">
                    <mat-error *ngIf=" (libelleFormControl.dirty || libelleFormControl.touched) ">
                        <div *ngIf="libelleFormControl.hasError('required')">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Libelle obligatoire !
                        </div>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="col-6" *ngIf="!ModeAjout">
            <div class="form-group">
                <mat-form-field class="w-100">
                    <mat-label>Statut</mat-label>
                    <input type="text"  matInput (change)="onStatusChange($event.target.value)" [formControl]="affecterCycleFormControl" name="workflow" [matAutocomplete]="autoWorkflowComm">
                    <mat-autocomplete autoActiveFirstOption [displayWith]="displayCycleVieFn" #autoWorkflowComm="matAutocomplete">
                        <mat-option *ngFor="let option of listCycleVieFilter | async" [value]="option">
                            {{option.etat}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="affecterCycleFormControl.hasError('invalidAutocompleteObject') &&  !affecterCycleFormControl.hasError('required')">
                        <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon> Merci de vérfier le Statut !
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        </div>
        <div class="row">
        <div class="col-6">
            <div class="div-list-no-existt principal-background mx-1 p-3  w-70">

                <div class="row w-100 justify-content-center text-center ">
                    <div class="col-12 justify-content-center text-center">
                        <label class="mb-2 ml-2 justify-content-center text-center">Ajouter des services</label>

                    </div>
                    <div class="col-12 justify-content-center text-center ">
                        <label class="mb-2 ml-2 title-style-normal principal-color  ">Servies</label>
                    </div>
                </div>


                <div class="row scroll">
                    <span class="example-list-section">
              
                          <div  *ngFor="let service of listServices">
                            <mat-checkbox   [(ngModel)]="service.selected"
                            (ngModelChange)="updateSelectedServices(service)">
                              {{service.libele}}
                            </mat-checkbox>
                          </div>
                    
                      </span>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="div-list-no-existt secondry-background mx-1 p-3  w-70">
                <div class="row justify-content-center background sticky-2">
                    <div class="col-12 text-center">
                        <label class="mb-2 ml-2 text-center ">Glissez chaque service pour les placer dans l’ordre
                            que vous
                            préférez.
                        </label>
                    </div>
                    <div class="col-12 text-center">
                        <label class="mb-2 ml-2 title-style-normal secondry-color ">Services choisis </label>

                    </div>
                </div>


                <div  cdkDropList (cdkDropListDropped)="onDrop($event)" class="  justify-content-center scroll">
                
                        <div class="  mx-auto list-item justify-content-center " *ngFor="let selectedService of selectedServices"
                        cdkDrag>
                        <div class="col-2 cursor  mt-3">
                            <img src="assets\imageSvg\dragDropIcon.svg" cdkDropList [cdkDropListOrientation]="'vertical'"
                            (cdkDropListDropped)="onDrop($event)">
                          

                        </div>
                        <div class="col w-70 mt-3 ml-2">
                            {{ selectedService.libele }}
                        </div>
                        <div class="col-2 w-100 mt-3 cursor">
                            <img src="assets\imageSvg\deleteIcon.svg"  (click)="deleteService(selectedService)">
                           
                        </div>
                
                       
                      </div>
                </div>
            </div>
        </div>
    </div>
 


</div>

