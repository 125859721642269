<ng-container appearance="standard">
    <form [formGroup]="formDocument" class="example-form">
        <div class="shadow-box-card-body">
            <div class=" row col-md-4">
                <mat-form-field class="full-width">
                    <ng-container *ngIf="loaderListDocument">
                        <span class="spinner-style position-absolute">
              <mat-spinner [diameter]="15"></mat-spinner>
            </span>
                    </ng-container>
                    <mat-label>
                        Ajouter Document
                    </mat-label>
                    <input formControlName="documentText" type="text" matInput [matAutocomplete]="auto">
                    <input formControlName="documentTextId" type="text" hidden>
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option (onSelectionChange)="onSelectDoc(item)" *ngFor="let item of docAutoComplete$ | async; let index = index" [value]="item.libelle">
                            {{item.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                    <fa-icon matSuffix [icon]="fa['faSearch']" style="color: rgb(110, 108, 108);padding-right:10px"></fa-icon>

                </mat-form-field>
            </div>
            <div class="table-responsive p-3">
                <table class="table">
                    <thead class="head-table">
                        <tr>
                            <th>#</th>
                            <th>Libellé</th>
                            <th>Obligatoire</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody class="body-table">
                        <tr *ngFor="let doc of defaultDocumentList; let index = index">
                            <td>{{ index+1 }}</td>
                            <td>{{ doc.libelle }}</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                        <tr *ngFor="let doc of typeDocuments; let index = index" [ngClass]="{'tdcolor' : !edit, 'tdcoloredit':id==doc.id && edit }">
                            <td> {{ defaultDocumentList.length+index+1 }}</td>
                            <td> {{ doc.libelle }}</td>
                            <td>
                                <label class="switch space-switch margin-checkbox">
                  <input class="checkbox" [checked]="doc.oblig == '1'" type="checkbox"
                  (change)="isObligatoire($event , doc)" name="checkbox">
                  <span class="slider round"></span>
                </label>
                            </td>
                            <td class="d-flex justify-content-center">
                        
                <button  type="button" class="btn-dt-delete"  (click)="getDocumentInfo(doc.id, index)">
                    <fa-icon [icon]="fa['faTrash']"></fa-icon>
                  </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p class="text-center" *ngIf="typeDocuments.length < 1 && defaultDocumentList.length < 1">
                    Pas des documents
                </p>
            </div>
        </div>
    </form>
</ng-container>
