import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as fa from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-version-convention',
  templateUrl: './version-convention.component.html',
  styleUrls: ['./version-convention.component.css']
})
export class VersionConventionComponent implements OnInit , OnChanges {
  fa = fa;
 @Input() organisme_id;
 @Input()  allOrganismeList;
 @Input() allGammesList;
 @Input() mode;
 @Input() disabledName;
 @Input() conventionData;
 @Input() type;
 @Output() onPushVersion: EventEmitter<any> = new EventEmitter();
  version: any;
  ConventionStatusFerme = environment.ConventionStatusFerme;
  ConventionStatusExpire = environment.ConventionStatusExpire;
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.onPushVersion.emit(this.version)
  }

  ngOnInit(): void {
  }
  getDataConvention(event){
    this.version = event;
    this.onPushVersion.emit(event)
  }
}
