<mat-accordion class="example-headers-align w-100 mt-3 custemPanel"
>
<mat-expansion-panel   >
    <mat-expansion-panel-header >
        <div class="row w-100">
            <div class="col-3 textVersion">
                Version {{conventionData.version}}
            </div>
            <div class="col-6">

            </div>
            <div class="col-1"> </div>
            <div class="col-2">
                <span class="btn-status d-flex justify-content-center"
                    [ngStyle]="{ color: conventionData.color, 'background-color': conventionData.background }">
                    {{conventionData.libelle}}
                </span>
            </div>

        </div>
    </mat-expansion-panel-header>
    <div class="row justify-content-center ">

        <app-form-convention 
        (onPushConvention)="getDataConvention($event)"
        [organisme_id]="organisme_id" 
        [allOrganismeList]="allOrganismeList" 
        [allGammesList]="allGammesList"
        [mode]="mode"
        [disabledName]="disabledName" 
        [conventionData]="conventionData"
        [type]="type"></app-form-convention>
        <div class="row justify-content-center mb-3" *ngIf="conventionData.status !== ConventionStatusFerme && conventionData.status !== ConventionStatusExpire">
        
            <button type="submit" class="btn-dt-save-popup  mr-2"
                 >
                <fa-icon class="fa-icon-style mt-1"
                    [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
                Enregistrer
            </button>

            <button type="button" class="btn-dt-cancel-popup"
               >
                <fa-icon class="fa-icon-style mt-1"
                    [icon]="fa['faTimesCircle']"></fa-icon>&nbsp;
                Annuler
            </button>
          <!--   <mat-spinner *ngIf="showloaderModif || showloader " [diameter]="20"
                class="ml-2 mt-1"></mat-spinner> -->
        </div>

    </div>

</mat-expansion-panel>

</mat-accordion>
