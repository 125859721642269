import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Process } from '../../../../entity/Process';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit  {
  @Input() listOfProcesses: Process [] = [];
  fa = fa
  constructor(private apiAffairesService: ApiAffairesService,
  ) { }



  ngOnInit(): void {
   /*  this.apiAffairesService.getlistBpmAffaire("b5f36b0d8346309331d8fd47fc6719ce").subscribe((data) => {
      console.log("daraaaaa", data);
      this.listOfProcess = data
    }); */
  }

}
