
import { Entreprise } from "../../../entity/Opportunity/Entreprise";
import { Documents } from '../../../entity/Affaires/Documents';
import { AncienDossier } from '../../../entity/Affaires/AncienDossier';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListVille } from './../../../entity/ListVille';
import { Parrainage } from './../../../entity/Generic/Parrainage';
import { ApiProspectsService } from "src/app/services/ApiProspects/api-prospects.service";
import { Prospect } from "src/app/entity/TicketsAndSupport/FilterTicket";


@Component({
  selector: 'app-prospect',
  templateUrl: './prospect.component.html',
  styleUrls: ['./prospect.component.css']
})
export class ProspectComponent  implements OnInit {
  fa = fa;
  @Input() opportuniteId;
  @Input() prospect: Prospect = new Prospect();
  @Output() onUpdateSociete: EventEmitter<Entreprise> = new EventEmitter<Entreprise>();
  typeProspect: string;
  entreprise: Entreprise = new Entreprise();
  document: AncienDossier = new AncienDossier();
  ancien_dossier: Documents = new Documents();
  listVille: ListVille[];
  parrainage: Parrainage = new Parrainage();

  constructor( private apiProspectService: ApiProspectsService,
    ) {
  }

  ngOnInit(): void {



  }


  getVilleList() {
    this.apiProspectService.getListVille(this.parrainage.cp_parrainage)
      .subscribe((data: ListVille[]) => {
        this.listVille = data;
      });
  }





  onUpdateEntreprise($event: Entreprise) {
    this.entreprise = $event;
    this.onUpdateSociete.emit($event);
  }
}
